html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Sansa";
  font-size: $font-sm;
  line-height: $lh-sm;

  background: -moz-linear-gradient(
    180deg,
    rgba(245, 156, 51, 1),
    rgb(250, 222, 189)
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(245, 156, 51, 1),
    rgb(250, 222, 189)
  );
  background: linear-gradient(
    180deg,
    rgba(245, 156, 51, 1),
    rgb(250, 222, 189)
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f59c33",endColorstr="#f59c33",GradientType=1);
  background-repeat: no-repeat;
  background-size: cover;

  @include breakpoint-down("phone") {
    background: -webkit-linear-gradient(
      180deg,
      rgba(245, 156, 51, 1),
      rgba(245, 156, 51, 1)
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f59c33",endColorstr="#f59c33",GradientType=1);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font-size: $font-xl;
  line-height: $lh-xl;
  font-weight: 600;
  color: white;

  @include breakpoint-up("desktop") {
    font-size: $font-xl-max;
    line-height: $lh-xl-max;
  }
  @include breakpoint-down("phone") {
    font-size: 7.5vw;
    line-height: 8.3vw;
  }
}

h2 {
  font-size: $font-md;
  line-height: $lh-md;
  font-weight: 600;

  @include breakpoint-up("desktop") {
    font-size: $font-md-max;
    line-height: $lh-md-max;
  }

  @include breakpoint-down("phone") {
    font-size: 4.1vw;
    line-height: 6.2vw;
  }
}

h4 {
  font-size: $font-sm;
  line-height: $lh-sm;
  margin: 1.6176vw 0;

  @include breakpoint-up("desktop") {
    font-size: $font-sm-max;
    line-height: $lh-sm-max;
    margin: 22px 0;
  }

  @include breakpoint-down("tablet") {
    font-size: 16px;
    line-height: 3.9vw;
  }
}

p {
  font-size: $font-sm;
  line-height: $lh-sm;
  margin: 1.6176vw 0;

  @include breakpoint-up("desktop") {
    font-size: $font-sm-max;
    line-height: $lh-sm-max;
    margin: 22px 0;
  }

  @include breakpoint-down("phone") {
    font-size: 2.7vw;
    line-height: 3.9vw;
  }
}
