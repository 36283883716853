@font-face {
  font-family: "Sansa";
  font-weight: 900;
  src: url("../fonts/SansaSlabPro-Black.otf") format("opentype");
}

@font-face {
  font-family: "Sansa";
  font-weight: 900;
  font-style: italic;
  src: url("../fonts/SansaSlabPro-BlackItalic.otf") format("opentype");
}

@font-face {
  font-family: "Sansa";
  font-weight: 700;
  src: url("../fonts/SansaSlabPro-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Sansa";
  font-weight: 700;
  font-style: italic;
  src: url("../fonts/SansaSlabPro-BoldItalic.otf") format("opentype");
}

@font-face {
  font-family: "Sansa";
  font-weight: 600;
  src: url("../fonts/SansaSlabPro-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "Sansa";
  font-weight: 600;
  font-style: italic;
  src: url("../fonts/SansaSlabPro-SemiBoldItalic.otf") format("opentype");
}

@font-face {
  font-family: "Sansa";
  font-weight: 400;
  src: url("../fonts/SansaSlabPro-Normal.otf") format("opentype");
}

@font-face {
  font-family: "Sansa";
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/SansaSlabPro-NormalItalic.otf") format("opentype");
}

@font-face {
  font-family: "Sansa";
  font-weight: bold;
  font-style: italic;
  src: url("../fonts/SansaSlabPro-Light.otf") format("opentype");
}

@font-face {
  font-family: "Sansa";
  font-weight: bold;
  font-style: italic;
  src: url("../fonts/SansaSlabPro-LightItalic.otf") format("opentype");
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans");

$font-sm-max: 1rem; //16pt
$lh-sm-max: 1.3rem; //21pt
$font-sm: 1.1764vw; //16pt
$lh-sm: 1.5441vw; //20pt

$font-md-max: 1.25rem; //20pt
$lh-md-max: 1.5rem; //24pt
$font-md: 1.5441vw; //20pt
$lh-md: 1.7647vw; //24pt

$font-lg-max: 2.25rem; //36pt
$lh-lg-max: 2.6875rem; //43pt
$font-lg: 2.647vw; //36pt
$lh-lg: 3.1617vw; //43pt

$font-xl-max: 3.09375rem; //49.5pt
$lh-xl-max: 3.75rem; //60pt
$font-xl: 3.6764vw; //49.5pt
$lh-xl: 4.4117vw; //60pt

.font {
  &--bold {
    font-weight: bold;
  }

  &--sm {
    font-size: $font-sm;
    line-height: $lh-sm;

    @include breakpoint-down("phone") {
      font-size: 3.3vw;
      line-height: 4.2vw;
    }

    @include breakpoint-up("desktop") {
      font-size: $font-sm-max;
      line-height: $lh-sm-max;
    }
  }

  &--md {
    font-size: $font-md;
    line-height: $lh-md;

    @include breakpoint-down("phone") {
      font-size: 4.1vw;
      line-height: 5vw;
    }

    @include breakpoint-up("desktop") {
      font-size: $font-md-max;
      line-height: $lh-md-max;
    }
  }

  &--lg {
    font-size: $font-lg;
    line-height: $lh-lg;

    @include breakpoint-down("phone") {
      font-size: 10vw;
      line-height: 12.5vw;
    }

    @include breakpoint-up("desktop") {
      font-size: $font-xl-max;
      line-height: $lh-xl-max;
    }
  }

  &--xl {
    font-size: $font-xl;
    line-height: $lh-xl;

    @include breakpoint-down("phone") {
      font-size: 10vw;
      line-height: 12.5vw;
    }

    @include breakpoint-up("desktop") {
      font-size: $font-xl-max;
      line-height: $lh-xl-max;
    }
  }
}
