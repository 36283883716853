@keyframes rotate-paused {
  0% {
    transform: rotate(0);
  }

  20% {
    transform: rotate(0);
  }

  45% {
    transform: rotate(360deg);
  }

  60% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0);
  }
}
