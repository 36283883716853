.popup {
  display: flex;
  justify-content: center;
  // align-items: center;

  .overlay {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(123, 193, 67, 0.5);
  }

  &__content {
    position: absolute;
    pointer-events: auto;
    background: white;
    color: black;

    position: fixed;
    width: 90%;
    height: auto;
    max-width: 540px;
    max-height: 600px;
    margin: 0 auto;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px 2px 2px 2px;

    .slick-slider {
      .slick-list {
        overflow-x: hidden;
        overflow-y: scroll;
        /* height: 500px; */

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        z-index: 10;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .slick-slide {
        z-index: 1;
        text-align: left;
        transition: opacity 1s;
        padding: 30px 70px 10px 50px;
        margin-bottom: 40px;

        @include breakpoint-down("phone") {
          padding: 30px 30px 10px 30px;
        }

        &:focus {
          outline: none;
        }

        a {
          color: #522e92;

          &:hover {
            color: #522e92;
            text-decoration: underline;
          }
        }

        h3 {
          color: #522e92;
          font-size: 1.8rem;
          line-height: 2rem;
          font-weight: 600;
          margin-bottom: 17px;
          font-family: "Sansa";
        }

        p,
        ul,
        ol {
          color: #000000;
          font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial",
            "Lucida Grande", sans-serif;
          margin-bottom: 17px;
          font-size: $font-sm-max;
          line-height: $lh-sm-max;
        }

        iframe {
          width: 100%;

          @include breakpoint-down("phone") {
            height: 200px;
          }
        }

        .mvs {
          .text {
            display: flex;
            margin-top: 10px;

            ul {
              margin: 0;
              padding-left: 25px;
            }

            p {
              padding-left: 25px;
            }

            img {
              width: 130px;
              height: 130px;
            }
          }
        }
      }

      .slick-arrow {
        position: absolute;
        border: none;
        z-index: 100;
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: 100%;
        height: 8%;
        width: 8%;
        margin: 2%;
        margin: 1.5%;
        padding: 4%;
        transform: translate(0, 215px);

        &::after,
        &::before {
          opacity: 0;
        }
      }

      .slick-prev {
        left: 0;
        bottom: 0;
        background-image: url("./images/arrow-left.png");

        &:hover {
          background-image: url("./images/arrow-left-hover.png");
        }
      }

      .slick-next {
        right: 0;
        bottom: 0;
        background-image: url("./images/arrow-right.png");

        &:hover {
          background-image: url("./images/arrow-right-hover.png");
        }
      }

      .slick-dots {
        display: flex !important;
        justify-content: center;
        align-items: center;
        height: 57px;
        margin: 0;
        padding: 0;
        -webkit-box-shadow: 0px -4px 4px -4px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0px -4px 4px -4px rgba(0, 0, 0, 0.5);
        box-shadow: 0px -4px 4px -4px rgba(0, 0, 0, 0.5);
        background: white;
        z-index: 99;

        li {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 5px;

          button {
            border-radius: 100%;
            width: 15px;
            height: 15px;
            padding: 5px;
            margin: 3px;
            border: 4px solid white;
            text-indent: -9999px;
            overflow: hidden;
            background: #d1d1d1;
            margin-top: 10px;
          }
        }

        .slick-active {
          button {
            border: 3px solid #522e92 !important;
            background: transparent !important;
            width: 15px;
            height: 15px;
          }
        }
      }

      img {
        width: 100%;
      }
    }
  }

  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: none;
  -o-transform: translate3d(0, 0, 0);
  transform: none;

  .slick-slide:not(.slick-current) video {
    visibility: hidden !important;
  }

  .slick-slide {
    width: 570px;
    height: 457px !important;
    overflow-y: scroll;
  }

  .slick-slide img {
    width: 100%;
  }
}
