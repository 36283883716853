.btn {
  width: 2.4264vw;
  height: 2.4264vw;

  @include breakpoint-up("desktop") {
    width: 33px;
    height: 33px;
  }

  @include breakpoint-down("phone") {
    width: 6.8vw;
    height: 6.8vw;
  }

  &:hover {
    cursor: pointer;
  }

  &--close {
    position: absolute;
    right: 0;
    transform: translateX(27px) translateY(-26px) rotate(45deg);

    @include breakpoint-down("phone") {
      transform: translateX(10px) translateY(-18px) rotate(45deg);
    }
  }

  &--rotate {
    animation: 4s ease-in-out infinite rotate-paused;
  }
}
