.coronaNumber {
  display: inline-block;
  text-align: center;
  color: black;
  font-weight: 300;
  position: absolute;

  img {
    width: 2.9411vw;
    position: absolute;
    left: -0.7352vw;
    top: -1.1029vw;

    @include breakpoint-up("desktop") {
      width: 40px;
    }
    @include breakpoint-down("phone") {
      width: 7vw;
      left: -1.5vw;
      top: -2.5vw;
    }
  }

  p {
    margin-top: 5px;
  }

  &__number {
    font-weight: bold;
    background-color: $orange;
    border-radius: 25px;
    border: 1.5px solid $hotpink;
    padding: 0px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $darkpurple;
    height: 2.2vw;

    p {
      margin: 0;
    }

    @include breakpoint-down("phone") {
      height: 100%;
    }
  }
}
