// 640px, 1024px, 1360px
$breakpoints-up: (
  "tablet": "40em",
  "laptop": "64em",
  "desktop": "85em",
);

// 480px, 1023px, 1359px
$breakpoints-down: (
  "phone": "30em",
  "tablet": "64em",
  "laptop": "84,9375em",
);

@mixin breakpoint-up($size) {
  @media (min-width: map-get($breakpoints-up, $size)) {
    @content;
  }
}

@mixin breakpoint-down($size) {
  @media (max-width: map-get($breakpoints-down, $size)) {
    @content;
  }
}
