.app {
  &__topbar {
    background-color: white;
    height: 10vw;
    display: flex;
    align-items: center;

    @include breakpoint-down("phone") {
      height: 20vw;
    }

    .logo {
      width: 12.8676vw;
      margin-left: 40px;

      @include breakpoint-down("phone") {
        width: 20vw;
      }
    }
  }

  &__hero {
    background-image: url(./images/SVG/top/bubblebg-desktop.svg);
    background-origin: border-box;
    background-repeat: no-repeat;
    background-position: left;
    position: relative;

    height: 44.9275vw;

    // Only IE11
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: inline-block;
    }

    @include breakpoint-up("desktop") {
      height: 620px;
    }

    @include breakpoint-down("phone") {
      background-image: url(./images/purplebgmob.svg);
      height: 118vh;
      background-position: top;
    }
  }

  &__content {
    background-image: url(./images/SVG/bg-desktop.svg);
    background-origin: border-box, border-box;
    background-repeat: no-repeat, no-repeat;
    background-position: top, top;
    width: 100%;
    min-height: 2000px;

    @include breakpoint-down("tablet") {
      min-height: 1500px;
    }

    @include breakpoint-down("phone") {
      background-image: url(./images/SVG/bg-mobile_3.svg),
        linear-gradient(180deg, rgba(245, 156, 51, 1), white);
      background-position: top, top;
      min-height: 664vw;
      position: absolute;
      top: 201vw;
    }
  }

  &__footer {
    position: relative;
    padding-top: 100px;

    @include breakpoint-down("phone") {
      position: absolute;
      width: 100%;
      top: 806.5vw;
    }

    @include breakpoint-down("tablet") {
      padding-top: 0;
    }

    .jaaronder {
      width: 50%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }

    .footerachtergrond {
      margin-top: 200px;
      width: 100%;
    }
  }
}
