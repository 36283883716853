.section {
  color: $darkpurple;
  margin: 2em 0;
  position: relative;

  &__title {
    background-color: white;
    display: inline-block;
    padding: 5px 15px;
    border-radius: 8px;
  }

  &__numbers {
    p {
      display: inline-block;
      margin: 0 1em;

      color: black;
      text-align: center;

      span {
        display: block;
        color: $darkpurple;
        font-weight: bold;
      }
    }
  }
}

.hero {
  position: relative;

  &__presentator {
    position: absolute;
    height: 19.8529vw;
    top: 13.9705vw;
    left: 2.2058vw;

    @include breakpoint-up("desktop") {
      height: 270px;
      top: 190px;
      left: 30px;
    }

    @include breakpoint-down("phone") {
      height: 70vw;
      top: 60vw;
    }
  }

  a {
    color: white;
  }

  .plusicon {
    height: 16px;

    @include breakpoint-down("tablet") {
      height: 10px;
    }
  }

  &__people {
    position: absolute;
    width: 41.1764vw;
    left: 47.1014vw;
    top: -7.7205vw;

    @include breakpoint-up("desktop") {
      width: 560px;
      left: 650px;
      top: -105px;
    }

    @include breakpoint-down("phone") {
      display: none;
    }

    &--mobile {
      display: none;

      @include breakpoint-down("phone") {
        display: block;
        position: absolute;
        left: -46vw;
        top: 123vw;
        width: 547px;
      }
    }
  }

  &__textballoon {
    background-image: url(../images/SVG/top/textballoon-desktop.svg);
    background-origin: border-box;
    background-repeat: no-repeat;
    height: 38.9705vw;

    margin-left: 9.1911vw;
    transform: translateY(-25px);
    padding: 1.81159vw;

    // Only IE11
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: inline-block;
    }

    @include breakpoint-up("desktop") {
      margin-left: 125px;
      height: 530px;
    }

    @include breakpoint-down("phone") {
      background-image: url(../images/SVG/top/textballoon-mobile.svg);
      background-position: right;
      height: 100vw;
    }

    .text {
      margin-left: 4%;
      width: 36.7391vw;
      max-width: 507px;
      color: white;

      @include breakpoint-down("phone") {
        width: 100%;
        max-width: 62.5vw;
        margin-left: 19vw;
        margin-top: 2vw;
      }

      .audioplayer {
        // height: 4.4117vw;

        @include breakpoint-up("desktop") {
          height: 60px;
          width: 507px;
        }
      }
    }

    .corona {
      display: flex;

      @include breakpoint-down("phone") {
        margin-top: 2.2vw;
      }

      &__toggle {
        margin-right: 20px;
      }

      &__text {
        p {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

.socials {
  .section__numbers {
    p {
      position: absolute;
    }

    p:nth-child(1) {
      left: 57.5vw;
      top: -1.8382vw;

      @include breakpoint-up("desktop") {
        left: 50%;
        transform: translateX(105px);
        top: -25px;
      }
      @include breakpoint-down("phone") {
        width: 20vw;
        top: 610.5vw;
        left: 27.7vw;
      }
    }
    p:nth-child(2) {
      left: 73vw;
      top: -1.8382vw;

      @include breakpoint-up("desktop") {
        left: 50%;
        transform: translateX(315px);
        top: -25px;
      }
      @include breakpoint-down("phone") {
        width: 20vw;
        top: 611.6vw;
        left: 57.7vw;
      }
    }
    p:nth-child(3) {
      left: 71vw;
      top: 7.5vw;

      @include breakpoint-up("desktop") {
        left: 50%;
        transform: translateX(284px);
        top: 102px;
      }
      @include breakpoint-down("phone") {
        width: 20vw;
        top: 631.6vw;
        left: 53.7vw;
      }
    }
    .vrouwenicon {
      fill: none;
      stroke: #fff;

      @include breakpoint-down("phone") {
        stroke: $orange;
      }

      .cls-1 {
        fill: none;
      }
    }
  }

  .coronaNumber {
    position: absolute;
    left: 54.0955vw;
    top: 11.0294vw;
    width: 11.0294vw;

    @include breakpoint-up("desktop") {
      width: 150px;
      top: 151px;
      left: 50%;
      transform: translate(60px, 0);
    }
    @include breakpoint-down("phone") {
      width: 26vw;
      top: 637.6vw;
      left: 19.7vw;
    }
  }
}

.ouders {
  .corona-addon {
    width: 7.35vw;
    position: absolute;
    left: 11.75vw;
    top: 17vw;

    @include breakpoint-up("desktop") {
      width: 100px;
      top: 230px;
      left: calc(50% - 520px);
    }

    @include breakpoint-down("phone") {
      width: 17.35vw;
      top: 177vw;
      left: 22vw;
    }
  }

  .section__title {
    position: absolute;
    top: 3.3vw;
    left: 32vw;

    @include breakpoint-up("desktop") {
      top: 45px;
      left: 50%;
      transform: translateX(-250px);
    }
    @include breakpoint-down("phone") {
      top: 150.7vw;
      left: 56.14vw;
    }
  }

  .btn--rotate {
    position: absolute;
    left: 42vw;
    top: 32.5vw;

    @include breakpoint-up("desktop") {
      top: 441px;
      left: calc(50% - 100px);
    }
    @include breakpoint-down("phone") {
      top: 206.7vw;
      left: 79.14vw;
    }
  }

  .section__numbers {
    p {
      position: absolute;
    }

    p:nth-child(1) {
      left: 16vw;
      top: 5.5vw;

      @include breakpoint-up("desktop") {
        top: 75px;
        left: 50%;
        transform: translateX(-464px);
      }
      @include breakpoint-down("phone") {
        top: 159.6vw;
        left: 28.7vw;
      }
    }
    p:nth-child(2) {
      left: 13vw;
      top: 36.5vw;

      @include breakpoint-up("desktop") {
        top: 495px;
        left: 50%;
        transform: translateX(-506px);
      }
      @include breakpoint-down("phone") {
        top: 223vw;
        left: 12.5vw;
      }
    }
  }

  .coronaNumber {
    width: 11.0294vw;
    left: 3.8vw;
    top: 28vw;

    @include breakpoint-up("desktop") {
      width: 150px;
      top: 380px;
      left: calc(50% - 635px);
    }
    @include breakpoint-down("phone") {
      width: 28vw;
      top: 195.7vw;
      left: 7vw;
    }
  }
}

.cjgRijnmond {
  .corona-addon {
    width: 12.86vw;
    position: absolute;
    left: 69vw;
    top: 24.6vw;

    @include breakpoint-up("desktop") {
      width: 175px;
      top: 335px;
      left: calc(50% + 260px);
    }
    @include breakpoint-down("phone") {
      width: 20.35vw;
      top: 254vw;
      left: 30.2vw;
    }
  }

  .section__title {
    position: absolute;
    top: 26vw;
    left: 79vw;

    @include breakpoint-up("desktop") {
      top: 355px;
      left: calc(50% + 410px);
    }
    @include breakpoint-down("phone") {
      top: 248.7vw;
      left: 50.14vw;
    }
  }

  .btn--rotate {
    position: absolute;
    left: 93vw;
    top: 44.85vw;

    @include breakpoint-up("desktop") {
      top: 610px;
      left: calc(50% + 580px);
    }
    @include breakpoint-down("phone") {
      top: 289.7vw;
      left: 79.14vw;
    }
  }

  .section__numbers {
    p {
      position: absolute;
    }

    p:nth-child(1) {
      top: 21vw;
      left: 92.7vw;

      @include breakpoint-up("desktop") {
        top: 285px;
        left: calc(50% + 582px);
      }
      @include breakpoint-down("phone") {
        top: 239.6vw;
        left: 70.7vw;
      }
    }
    p:nth-child(2) {
      top: 48vw;
      left: 84vw;

      @include breakpoint-up("desktop") {
        top: 650px;
        left: calc(50% + 465px);
      }
      @include breakpoint-down("phone") {
        top: 291vw;
        left: 56.5vw;
      }
    }
    p:nth-child(3) {
      top: 20.6vw;
      left: 81.9vw;

      @include breakpoint-up("desktop") {
        top: 280px;
        left: calc(50% + 435px);
      }
      @include breakpoint-down("phone") {
        top: 241vw;
        left: 46.5vw;
      }
    }
  }

  .coronaNumber {
    width: 11.0294vw;
    left: 61vw;
    top: 42.3vw;

    @include breakpoint-up("desktop") {
      width: 150px;
      top: 575px;
      left: calc(50% + 160px);
    }
    @include breakpoint-down("phone") {
      width: 28vw;
      top: 283.7vw;
      left: 10vw;
    }
  }
}

.scholen {
  .corona-addon {
    width: 19.11vw;
    position: absolute;
    top: 47.8vw;
    left: 21vw;

    @include breakpoint-up("desktop") {
      width: 260px;
      top: 650px;
      left: calc(50% - 460px);
    }
    @include breakpoint-down("phone") {
      width: 31vw;
      top: 452vw;
      left: 63.8vw;
    }
  }

  .section__title {
    position: absolute;
    top: 52.2vw;
    left: 7.5vw;

    @include breakpoint-up("desktop") {
      top: 710px;
      left: calc(50% - 570px);
    }
    @include breakpoint-down("phone") {
      width: 23.35vw;
      top: 459.4vw;
      left: 33vw;
    }
  }

  .btn--rotate {
    position: absolute;
    left: 21vw;
    top: 64.5vw;

    @include breakpoint-up("desktop") {
      top: 875px;
      left: calc(50% - 400px);
    }
    @include breakpoint-down("phone") {
      top: 483.7vw;
      left: 60.14vw;
    }
  }

  .section__numbers {
    p {
      position: absolute;
    }

    p:nth-child(1) {
      top: 53.3vw;
      left: 1vw;

      @include breakpoint-up("desktop") {
        top: 725px;
        left: calc(50% - 675px);
      }
      @include breakpoint-down("phone") {
        width: 21.8vw;
        top: 465.6vw;
        left: 7.7vw;
      }
    }
    p:nth-child(2) {
      top: 69.12vw;
      left: 15vw;

      @include breakpoint-up("desktop") {
        top: 940px;
        left: calc(50% - 480px);
      }
      @include breakpoint-down("phone") {
        width: 25vw;
        top: 492vw;
        left: 48vw;
      }
    }
    p:nth-child(3) {
      top: 71.32vw;
      left: 9vw;

      @include breakpoint-up("desktop") {
        top: 970px;
        left: calc(50% - 570px);
      }
      @include breakpoint-down("phone") {
        width: 25vw;
        top: 501vw;
        left: 27vw;
      }
    }
  }

  .coronaNumber {
    width: 11.0294vw;
    top: 53.3vw;
    left: 21vw;

    @include breakpoint-up("desktop") {
      width: 150px;
      top: 725px;
      left: calc(50% - 400px);
    }
    @include breakpoint-down("phone") {
      width: 33vw;
      top: 461.7vw;
      left: 60vw;
    }
  }
}

.kinderen {
  .section__title {
    position: absolute;
    top: 56.7vw;
    left: 45.14vw;

    @include breakpoint-up("desktop") {
      top: 771px;
      left: calc(50% - 70px);
    }

    @include breakpoint-down("phone") {
      top: 44.7vw;
      left: 29.14vw;
    }
  }

  .btn--rotate {
    position: absolute;
    left: 55.5vw;
    top: 104.4vw;

    @include breakpoint-up("desktop") {
      top: 1420px;
      left: calc(50% + 80px);
    }

    @include breakpoint-down("phone") {
      top: 134.7vw;
      left: 59.14vw;
    }
  }

  .section__numbers {
    p {
      position: absolute;
    }

    p:nth-child(1) {
      top: 84.6vw;
      left: 50.7vw;

      @include breakpoint-up("desktop") {
        top: 1150px;
        left: calc(50%);
      }

      @include breakpoint-down("phone") {
        top: 102.6vw;
        left: 44.7vw;
      }
    }
    p:nth-child(2) {
      top: 80.9vw;
      left: 64.5vw;

      @include breakpoint-up("desktop") {
        top: 1100px;
        left: calc(50% + 190px);
      }
      @include breakpoint-down("phone") {
        top: 88vw;
        left: 68.5vw;
      }
    }
    p:nth-child(3) {
      top: 94.11vw;
      left: 41.47vw;

      @include breakpoint-up("desktop") {
        top: 1280px;
        left: calc(50% - 150px);
      }
      @include breakpoint-down("phone") {
        top: 122vw;
        left: 19.5vw;
      }
    }
  }

  .corona-addon {
    width: 42vw;
    position: absolute;
    top: 50.7vw;
    left: 36.1vw;

    @include breakpoint-up("desktop") {
      width: 570px;
      top: 692px;
      left: calc(50% + -189px);
    }

    @include breakpoint-down("phone") {
      width: 76.5vw;
      top: 36vw;
      left: 18vw;
    }
  }

  .coronaNumber {
    width: 11.0294vw;
    top: 56.7vw;
    left: 69.5vw;

    @include breakpoint-up("desktop") {
      width: 150px;
      top: 780px;
      left: calc(50% + 250px);
    }

    @include breakpoint-down("phone") {
      width: 28vw;
      top: 46.7vw;
      left: 69vw;
    }
  }
}

.zorg {
  .section__title {
    position: absolute;
    top: 94.6vw;
    left: 15.8vw;

    @include breakpoint-up("desktop") {
      top: 1290px;
      left: calc(50% - 460px);
    }
    @include breakpoint-down("phone") {
      width: 16.35vw;
      top: 309.4vw;
      left: 41vw;
    }
  }

  .btn--rotate {
    position: absolute;
    top: 111vw;
    left: 25.8vw;

    @include breakpoint-up("desktop") {
      top: 1520px;
      left: calc(50% - 330px);
    }
    @include breakpoint-down("phone") {
      top: 342.7vw;
      left: 65.14vw;
    }
  }

  .section__numbers {
    p {
      position: absolute;
    }

    p:nth-child(1) {
      width: 11vw;
      top: 99.2vw;
      left: 1.8vw;

      @include breakpoint-up("desktop") {
        width: 150px;
        top: 1350px;
        left: calc(50% - 660px);
      }
      @include breakpoint-down("phone") {
        top: 315.6vw;
        left: 16.7vw;
      }
    }
    p:nth-child(2) {
      width: 11vw;
      top: 115.6vw;
      left: 2.6vw;

      @include breakpoint-up("desktop") {
        width: 150px;
        top: 1570px;
        left: calc(50% - 650px);
      }
      @include breakpoint-down("phone") {
        top: 351vw;
        left: 24.5vw;
      }
    }
  }

  .corona-addon {
    width: 7vw;
    position: absolute;
    top: 115.4vw;
    left: 31vw;

    @include breakpoint-up("desktop") {
      width: 95px;
      top: 1570px;
      left: calc(50% - 260px);
    }
    @include breakpoint-down("phone") {
      width: 18.35vw;
      top: 359vw;
      left: 75.8vw;
    }
  }

  .coronaNumber {
    width: 11.0294vw;
    top: 115.4vw;
    left: 19.4vw;

    @include breakpoint-up("desktop") {
      width: 150px;
      top: 1570px;
      left: calc(50% - 420px);
    }
    @include breakpoint-down("phone") {
      width: 28vw;
      top: 353.7vw;
      left: 50vw;
    }
  }
}

.gemeenten {
  .section__title {
    position: absolute;
    top: 94.6vw;
    left: 85.85vw;

    @include breakpoint-up("desktop") {
      top: 1290px;
      left: calc(50% + 505px);
    }
    @include breakpoint-down("phone") {
      width: 30.35vw;
      top: 535.4vw;
      left: 43vw;
    }
  }

  .btn--rotate {
    position: absolute;
    top: 121.5vw;
    left: 95.5vw;

    @include breakpoint-up("desktop") {
      top: 1650px;
      left: calc(50% + 623px);
    }
    @include breakpoint-down("phone") {
      top: 589.7vw;
      left: 65.14vw;
    }
  }

  .section__numbers {
    p {
      position: absolute;
    }

    p:nth-child(1) {
      width: 11vw;
      top: 88.2vw;
      left: 85.8vw;

      @include breakpoint-up("desktop") {
        width: 150px;
        top: 1200px;
        left: calc(50% + 490px);
      }
      @include breakpoint-down("phone") {
        width: 20vw;
        top: 525.6vw;
        left: 44.7vw;
      }
    }
    p:nth-child(2) {
      width: 11vw;
      top: 115.2vw;
      left: 80.8vw;

      @include breakpoint-up("desktop") {
        width: 150px;
        top: 1570px;
        left: calc(50% + 420px);
      }
      @include breakpoint-down("phone") {
        width: 25vw;
        top: 576vw;
        left: 34.5vw;
      }
    }
    p:nth-child(3) {
      width: 11vw;
      top: 121.5vw;
      left: 84.5vw;

      @include breakpoint-up("desktop") {
        width: 150px;
        top: 1650px;
        left: calc(50% + 470px);
      }
      @include breakpoint-down("phone") {
        width: 25vw;
        top: 589vw;
        left: 40vw;
      }
    }
    p:nth-child(4) {
      width: 11vw;
      top: 106.5vw;
      left: 85.3vw;

      @include breakpoint-up("desktop") {
        width: 150px;
        top: 1450px;
        left: calc(50% + 481px);
      }
      @include breakpoint-down("phone") {
        width: 20vw;
        top: 559vw;
        left: 46vw;
      }
    }
  }

  .corona-addon {
    width: 4.7vw;
    position: absolute;
    top: 113vw;
    left: 74vw;

    @include breakpoint-up("desktop") {
      width: 65px;
      top: 1550px;
      left: calc(50% + 330px);
    }
  }

  .coronaNumber {
    width: 11.0294vw;
    top: 120vw;
    left: 72vw;

    @include breakpoint-up("desktop") {
      width: 150px;
      top: 1645px;
      left: calc(50% + 305px);
    }
    @include breakpoint-down("phone") {
      width: 20vw;
      top: 562.7vw;
      left: 15vw;
    }
  }
}

.jeugd {
  .section__title {
    position: absolute;
    top: 116vw;
    left: 46.5vw;

    @include breakpoint-up("desktop") {
      top: 1580px;
      left: calc(50% - 35px);
    }
    @include breakpoint-down("phone") {
      width: 54.35vw;
      top: 382.4vw;
      left: 28vw;
    }
  }

  .btn--rotate {
    position: absolute;
    top: 142.5vw;
    left: 56.5vw;

    @include breakpoint-up("desktop") {
      top: 1930px;
      left: calc(50% + 93px);
    }
    @include breakpoint-down("phone") {
      top: 428.7vw;
      left: 56.14vw;
    }
  }

  .section__numbers {
    p {
      position: absolute;
    }

    p:nth-child(1) {
      width: 11vw;
      top: 130vw;
      left: 30vw;

      @include breakpoint-up("desktop") {
        width: 150px;
        top: 1770px;
        left: calc(50% - 275px);
      }
      @include breakpoint-down("phone") {
        width: 21.8vw;
        top: 405.6vw;
        left: 5.7vw;
      }
    }
    p:nth-child(2) {
      width: 11vw;
      top: 130vw;
      left: 62vw;

      @include breakpoint-up("desktop") {
        width: 150px;
        top: 1770px;
        left: calc(50% + 170px);
      }
      @include breakpoint-down("phone") {
        width: 24vw;
        top: 400vw;
        left: 67vw;
      }
    }
    p:nth-child(3) {
      width: 11vw;
      top: 143vw;
      left: 55vw;

      @include breakpoint-up("desktop") {
        width: 150px;
        top: 1940px;
        left: calc(50% + 66px);
      }
      @include breakpoint-down("phone") {
        width: 25vw;
        top: 426vw;
        left: 55vw;
      }
    }
  }

  .corona-addon {
    width: 2.6vw;
    position: absolute;
    top: 134.3vw;
    left: 44.3vw;

    @include breakpoint-up("desktop") {
      width: 35px;
      top: 1820px;
      left: calc(50% - 80px);
    }
    @include breakpoint-down("phone") {
      width: 7vw;
      top: 414vw;
      left: 32.8vw;
    }
  }

  .coronaNumber {
    width: 11.0294vw;
    top: 142.5vw;
    left: 40vw;

    @include breakpoint-up("desktop") {
      width: 150px;
      top: 1930px;
      left: calc(50% - 138px);
    }
    @include breakpoint-down("phone") {
      width: 28vw;
      top: 431.7vw;
      left: 20vw;
    }
  }
}

.coronaPopup {
  .coronaButton {
    width: 40px;
    position: absolute;
    left: 76vw;
    bottom: 19.5vw;
    animation: 10s ease-in-out infinite rotate-paused;

    &:hover {
      cursor: pointer;
    }

    @include breakpoint-up("desktop") {
      width: 40px;
      left: 1045px;
      bottom: 283px;
    }

    @include breakpoint-down("phone") {
      width: 5.2vw;
      left: 62%;
      top: 133vw;
    }
  }

  .btn--rotate {
    position: absolute;
    top: 27.5vw;
    left: 81.5vw;

    @include breakpoint-up("desktop") {
      top: 375px;
      left: 1120px;
    }
    @include breakpoint-down("phone") {
      top: 143vw;
      left: 73.14vw;
    }
  }
}
